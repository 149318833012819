<template>
  <div id="popupWrapper">
    <ejs-dialog
        ref="roomNumberRegistrationPopup"
        header="객실번호등록"
        width="459"
        allowDragging="true"
        showCloseIcon="true"
        isModal="false"
        enableResize="true"
        @close="close"
        :cssClass="
        newSaveCheck
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
    >

      <div class="window advancePaymentDepositInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">정보입력</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 사업장</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="roomBizDiv"
                                          id="roomBizDiv"
                                          v-model="roomInfo.roomBizDiv"
                                          :dataSource="roomBizDivSource"
                                          :allowFiltering="false"
                                          :fields="commonCodeFields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 ID</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="roomId"
                                          v-model="roomInfo.roomId"
                                          :disabled="newSaveCheck"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 동구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="dongDiv"
                                          id="dongDiv"
                                          v-model="roomInfo.dongDiv"
                                          :dataSource="dongDivSource"
                                          :allowFiltering="false"
                                          :fields="commonCodeFields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="roomNo"
                                          v-model="roomInfo.roomNo"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 타입</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="roomType"
                                          id="roomType"
                                          v-model="roomInfo.roomType"
                                          :dataSource="roomTypeSource"
                                          :allowFiltering="false"
                                          :fields="commonCodeFields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 층</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                          ref="floor"
                                          v-model="roomInfo.floor"
                                          :propMaxLength="5"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 방향</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="roomDrc"
                                          id="roomDrc"
                                          v-model="roomInfo.roomDrc"
                                          :dataSource="roomDrcSource"
                                          :allowFiltering="false"
                                          :fields="commonCodeFields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 유형</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="roomKind"
                                          id="roomKind"
                                          v-model="roomInfo.roomKind"
                                          :dataSource="roomKindSource"
                                          :allowFiltering="false"
                                          :fields="commonCodeFields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">객실 상태</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="roomStatus"
                                          id="roomStatus"
                                          v-model="roomInfo.roomStatus"
                                          :dataSource="roomStatusSource"
                                          :allowFiltering="false"
                                          :fields="commonCodeFields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">청소 상태</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="cleanStatus"
                                          id="cleanStatus"
                                          v-model="roomInfo.cleanStatus"
                                          :dataSource="cleanStatusSource"
                                          :allowFiltering="false"
                                          :fields="commonCodeFields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">기준 요금</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                          ref="baseFee"
                                          v-model="roomInfo.stdPaymt"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">객실 전화번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                          ref="roomCallNumber"
                                          v-model="roomInfo.roomTelNo"
                                          :max-length="11"
                                      />
<!--                                      <input-text-->
<!--                                          ref="roomCallNumber"-->
<!--                                          v-model="roomInfo.roomCallNumber"-->
<!--                                      />-->
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">열번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                          ref="sortNo"
                                          v-model="roomInfo.columnNo"
                                          :propMaxLength="5"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">행번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                          ref="sortNo"
                                          v-model="roomInfo.rowNo"
                                          :propMaxLength="5"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">정렬번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                          ref="sortNo"
                                          v-model="roomInfo.sortNo"
                                          :propMaxLength="5"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">인터페이스 객실번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="ifRoomNumber"
                                          v-model="roomInfo.intrfcRoomNo"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">인터페이스 카드키번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                          ref="ifCardNumber"
                                          v-model="roomInfo.intrfcCardkeyNo"
                                          :max-length="6"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">룸 메이드 번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="roomMaidNumber"
                                          v-model="roomInfo.roomMaidNo"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">전용 사용여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                                type="checkbox"
                                                v-model="roomInfo.dvrUseFlag"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">전용 사용자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="privateUserName"
                                          v-model="roomInfo.dvrUserName"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">흡연 가능여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                                type="checkbox"
                                                v-model="roomInfo.smokingAbleFlag"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">가상 객실여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                                type="checkbox"
                                                v-model="roomInfo.virtlRoomFlag"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">사용여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                                type="checkbox"
                                                v-model="roomInfo.useFlag"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
<!--                                      <input-text-->
<!--                                          ref="useFlag"-->
<!--                                          v-model="roomInfo.useFlag"-->
<!--                                      />-->
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                          ref="bigo"
                                          id="bigo"
                                          v-model="roomInfo.remarks"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button custom-btn">
            <li class="delete">
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  :is-shortcut-button="true"
                  :disabled="!newSaveCheck"
                  @click.native="onDeleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
          </ul>
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="saveCheck"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="hideClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import componentTelephone from "@/components/ComponentTelephone";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import {commonCodeGetComCodeDefaultValue, commonCodesGetCommonCode} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { validateFormRefs } from "@/utils/formUtil";
import { deepDiffs } from "@/utils/ObjectUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "RoomNumberRegistrationPopup",
  mixins: [messagePopupDialogMixin,commonMixin],
  components: {
    InputNumber,
    InputTextarea,
    InputText,
    componentTelephone,
    ErpButton,
  },
  data() {
    return {
      commonCodeFields: { text: "comName", value: "comCode" },
      roomBizDivSource: commonCodesGetCommonCode("ROOM_BIZ_DIV"),
      dongDivSource: commonCodesGetCommonCode("DONG_DIV"),
      roomTypeSource: commonCodesGetCommonCode("ROOM_TYPE"),
      roomDrcSource: commonCodesGetCommonCode("ROOM_DRC"),
      roomKindSource: commonCodesGetCommonCode("ROOM_KIND"),
      roomStatusSource: commonCodesGetCommonCode("ROOM_STATUS"),
      cleanStatusSource: commonCodesGetCommonCode("CLEAN_STATUS"),
      newSaveCheck:false,
      oldData: null,
      changeCheckData:0,
      saveStatus:null,
      roomInfo: {
        roomBizDiv: null, // 객실사업장
        roomId: null, // 객실ID
        dongDiv: null, // 객실 동 구분
        roomNo: null, // 객실 번호
        roomType: null, // 객실 타입
        floor: null, // 객실 층
        roomDrc:null, //객실 방향
        roomKind:null, //객실 유형
        roomStatus: null, // 객실 상태
        cleanStatus: null, // 청소상태
        stdPaymt: 0, // 기준요금
        roomTelNo: null, //객실 전화번호
        columnNo: null,//열번호
        rowNo: null,//행번호
        sortNo: null, // 정렬번호
        intrfcRoomNo: null, // 인터페이스 객실번호
        intrfcCardkeyNo: null, // 인터페이스 카드키번호
        roomMaidNo: null, // 룸 메이드 번호
        dvrUseFlag: false, // 전용 사용여부
        dvrUserName: null, // 전용 사용자명
        smokingAbleFlag: false, // 흡연 가능여부
        virtlRoomFlag: false, // 가상 객실여부
        useFlag: false, // 사용여부
        remarks: null, // 비고
      },
      rules: {
        roomBizDiv: {
          required: true,
        },
        roomId: {
          required: true,
        },
        dongDiv: {
          required: true,
        },
        roomNo: {
          required: true,
        },
        roomType: {
          required: true,
        },
        floor: {
          required: true,
        },
        roomDrc: {
          required: true,
        },
        roomKind: {
          required: true,
        },
        roomStatus: {
          required: true,
        },
        cleanStatus: {
          required: true,
        },
        columnNo: {
          required: true,
        },
        rowNo: {
          required: true,
        },
        sortNo: {
          required: true,
        },
      },
    };
  },
  methods: {
    validateFormRefs,
    async saveCheck() {
      if(this.validateFormRefs(this.rules)){
       await this.save();
      }
    },
    async save() {
      if(this.newSaveCheck){
       const changes = deepDiffs(this.oldData, this.roomInfo);
       this.changeCheckData = Object.keys(changes).filter((item)=>item!=="roomId").length;
       if(!this.changeCheckData){
         this.errorToast("수정된 데이터가 없습니다");
         return;
       }
       await GolfErpAPI.patchRoomDetailNumber(changes);
        this.$nextTick(() => {
          this.infoToast("수정이 완료되었습니다");
        });
        await this.showPopup(this.roomInfo.roomId,true);
        return;
      }
     this.saveStatus =  await GolfErpAPI.postRoomNumber(this.roomInfo);
      this.$nextTick(() => {
        this.infoToast("저장이 완료되었습니다");
      });
      await this.showPopup(this.roomInfo.roomId,true);
    },
    async onDeleteButtonClicked() {
        if (
            await this.confirm(
                `객실을 삭제 하시겠습니까?`
            )
        ) {
          await GolfErpAPI.deleteRoomDetailNumber(this.roomInfo.roomId);
          await this.infoToast("삭제되었습니다");
          await this.$emit("fetch");
          await this.$emit("popupClosed");
        }

    },
    async showPopup(data,check=false) {
      this.newSaveCheck = check;
      if(this.newSaveCheck){
        const list = await GolfErpAPI.getRoomDetailNumber(data);
        this.roomInfo = {
          ...list
        };
        this.oldData = JSON.parse(JSON.stringify(list));
        delete this.oldData.roomId;
      }else {
        const room = this.roomInfo;
        room.roomBizDiv = commonCodeGetComCodeDefaultValue("ROOM_BIZ_DIV");
        room.dongDiv = commonCodeGetComCodeDefaultValue("DONG_DIV");
        room.roomType = commonCodeGetComCodeDefaultValue("ROOM_TYPE");
        room.roomDrc = commonCodeGetComCodeDefaultValue("ROOM_DRC");
        room.roomKind = commonCodeGetComCodeDefaultValue("ROOM_KIND");
        room.roomStatus = commonCodeGetComCodeDefaultValue("ROOM_STATUS");
        room.cleanStatus = commonCodeGetComCodeDefaultValue("CLEAN_STATUS");
      }
    },
    async close() {
      if(this.changeCheckData||this.saveStatus==="OK") {
        await this.$emit("fetch");
      }
      await this.$emit("popupClosed");
    },
    async hideClose() {
      await this.$refs["roomNumberRegistrationPopup"].hide();
    },
  },
};
</script>
<style scoped>
.check {
  display: flex !important;
  flex-flow: row nowrap;
  justify-content: center;
}
.custom-btn {
  float: left !important;
}
.title {
  width: 165px !important;
}
</style>