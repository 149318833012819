<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">객실사업장</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="roomInfo.roomBizDiv"
                    :fields="{ text: 'comName', value: 'comCode' }"
                    :dataSource="isRoomBizDivSource"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">객실타입</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="roomInfo.roomType"
                    :dataSource="isRoomTypeSource"
                    :fields="{ text: 'comName', value: 'comCode' }"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">동 구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="roomInfo.roomBuilding"
                    :dataSource="isRoomBuildingSource"
                    :fields="{ text: 'comName', value: 'comCode' }"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          value="null"
                          @change="(!roomInfo.useFlag)?roomInfo.useFlag=null:roomInfo.useFlag=true"
                          v-model="roomInfo.useFlag"
                      />
                      <i></i>
                      <div class="label">사용여부</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          value="null"
                          v-model="roomInfo.virtualCk"
                          @change="(roomInfo.virtualCk)?roomInfo.virtualFlag=false:roomInfo.virtualFlag=null"
                      />
                      <i></i>
                      <div class="label">가상객실 제외</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="fetch"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">객실번호 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-key-color="true"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onSaveClick"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="registerRoomnumber.shortcuts.addRoomNumber"
                      :shortcut="{key: 'F3'}"
                      :ignore="isPopupOpened"
                      :disabled="isModifyMode"
                      @click.native="addRoomNumber"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      :disabled="isModifyMode"
                      @click.native="deleteClick"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                      v-model="isModifyMode"
                      :checked="isModifyMode"
                      @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind="gridOptions"
                  :allowExcelExport="true"
                  :dataSource="roomListSource"
                  :selectionSettings="selectionSettings"
                  :allowFiltering="!isModifyMode"
                  :allowSorting="!isModifyMode"
                  :isAutoSelectCell="!isModifyMode"
                  @recordClick="recordClick"
                  @headerCellInfo="headerCellInfo"
                  @queryCellInfo="queryCellInfo"
                  @cellSelected="gridCellSelected"
                  @actionComplete="onGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <room-number-registration-popup
        ref="roomNumberRegistrationPopup"
        v-if="isroomNumberRegistrationPopup"
        @fetch="fetch"
        @popupClosed="popupClosed"
    />
    <edit-multiple-columns-popup
        v-if="isEditMultipleColumnsPopupOpen"
        ref="editMultipleColumnsPopup"
        @popupConfirm="onEditMultipleColumnsPopupConfirm"
        @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.dbSchema .content .item {width: 120px;}
body .appContent .lookup-condition .field.groupCodeGroupCodeName .content .item {width: 200px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import roomNumberRegistrationPopup from "@/views/code-management/popup/RoomNumberRegistrationPopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  Edit,
  ForeignKey,
  Resize,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import { commonCodesGetColorValue,commonCodesGetCommonCode } from "@/utils/commonCodes";
import { orderBy as _orderBy, } from "lodash";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "RegisterRoomNumber",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    roomNumberRegistrationPopup,
    editMultipleColumnsPopup,
    ErpButton,
  },
  async created() {

  },
  data() {
    return {
      roomWorkplaceSource:commonCodesGetCommonCode("ROOM_BIZ_DIV"),
      roomTypeSource:commonCodesGetCommonCode("ROOM_TYPE"),
      roomBuildingSource:commonCodesGetCommonCode("DONG_DIV"),
      isroomNumberRegistrationPopup:false,
      isEditMultipleColumnsPopupOpen:false,
      clickRoomId:null,
      roomList: [],
      roomListSource:[],
      count: 0,
      roomInfo: {
        sortNo: null, // 정렬번호
        roomBizDiv: "", // 객실사업장
        roomID: null, // 객실ID
        roomType: "", // 객실타입
        roomBuilding: "", // 동
        roomNumber: null, // 객실번호
        roomFloor: null, // 층
        roomColumn:null, //열
        roomLine:null, //행
        roomSort: null, // 정렬
        roomStatus: null, // 객실상태
        roomClean: null, // 청소상태
        roomDirection: null, // 객실방향
        roomCategory: null, // 객실유형
        virtualFlag: null, // 가상여부
        useFlag: null, // 사용여부
        virtualCk:null, // check 박스 체크
      },
      selectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
          this.isroomNumberRegistrationPopup ||
          this.isEditMultipleColumnsPopupOpen
      );
    },
      gridOptions() {
      return {
        provides: [Edit, ForeignKey, Resize, ExcelExport],
        allowResizing: true,
        columns: [
          {
            headerText: "객실사업장",
            field: "roomBizDiv",
            allowEditing:false,
            type: "string",
            textAlign: "Center",
            minWidth: 16,
            width: 200,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'ROOM_BIZ_DIV',
          },
          {
            headerText: "객실ID",
            allowEditing: false,
            textAlign: "Center",
            type: "string",
            field: "roomId",
            width: 80,
            isPrimaryKey: true,
          },
          {
            headerText: "객실타입",
            allowEditing: true,
            textAlign: "Center",
            type: "string",
            field: "roomType",
            width: 80,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'ROOM_TYPE',
          },
          {
            headerText: "동",
            textAlign: "Center",
            type: "string",
            field: "dongDiv",
            allowEditing: true,
            width: 80,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'DONG_DIV',
          },
          {
            headerText: "객실번호",
            textAlign: "Center",
            type: "string",
            field: "roomNo",
            width: 120,
            allowEditing: false,
          },
          {
            field: "floor",
            type: "string",
            headerText: "층",
            textAlign: "Center",
            width: 60,
            allowEditing: true,
          },
          {
            field: "columnNo",
            type: "string",
            headerText: "열번호",
            textAlign: "Center",
            width: 80,
            allowEditing: true,
          },
          {
            field: "rowNo",
            type: "string",
            headerText: "행번호",
            textAlign: "Center",
            width: 80,
            allowEditing: true,
          },
          {
            headerText: "정렬",
            textAlign: "center",
            type: "string",
            field: "sortNo",
            allowEditing: true,
            width: 100,
          },
          {
            headerText: "객실상태",
            textAlign: "Center",
            type: "string",
            field: "roomStatus",
            allowEditing: true,
            width: 100,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'ROOM_STATUS',
          },
          {
            headerText: "청소상태",
            textAlign: "Center",
            type: "string",
            field: "cleanStatus",
            allowEditing: true,
            width: 150,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'CLEAN_STATUS',
          },
          {
            headerText: "객실방향",
            textAlign: "Center",
            type: "string",
            field: "roomDrc",
            allowEditing: false,
            width: 120,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'ROOM_DRC',
          },
          {
            headerText: "객실유형",
            textAlign: "Center",
            type: "string",
            field: "roomKind",
            allowEditing: false,
            width: 100,
            editType: 'dropdownedit',
            isCommonCodeField: true,
            groupCode: 'ROOM_KIND',
          },
          {
            headerText: "가상여부",
            textAlign: "Center",
            field: "virtlRoomFlag",
            allowEditing: false,
            width: 100,
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
          },
          {
            headerText: "사용여부",
            textAlign: "Center",
            field: "useFlag",
            allowEditing: false,
            width: 100,
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
          },
        ],
      };
    },
    isRoomBizDivSource() {
      const Source = this.roomWorkplaceSource ;
      Source.unshift({
        comCode: "",
        comName: "전체",
      });
      return Source;
    },
    isRoomTypeSource() {
      const Source = this.roomTypeSource ;
      Source.unshift({
        comCode: "",
        comName: "전체",
      });
      return Source;
    },
    isRoomBuildingSource() {
      const Source = this.roomBuildingSource ;
      Source.unshift({
        comCode: "",
        comName: "전체",
      });
      return Source;
    },
  },
  methods: {
    async fetch() {
      this.roomList =  await GolfErpAPI.getRoomNumber(this.roomInfo);
      this.roomListSource = _orderBy(this.roomList,["roomBizDiv","dongDiv","roomNo"]);
    },
    async onSaveClick() {
      let changeData = this.$refs.grid.patchBatchChanges();
      if (changeData.changedRecords && changeData.changedRecords.length > 0) {
        await GolfErpAPI.patchRoomDetailNumberSeveral(changeData.changedRecords);
        await this.infoToast("수정되었습니다");
        await this.fetch();
      } else {
        this.errorToast("수정된 데이터가 없습니다");
      }
    },
    recordClick(args) {
      if (args.column && args.column.field === "roomNo") {
        this.isroomNumberRegistrationPopup = true;
        this.$nextTick(() => {
          this.$refs.roomNumberRegistrationPopup.showPopup(args.rowData.roomId,true);
        });
      }
    },
    async deleteClick() {
      const selectRecord = this.$refs.grid.getSelectedRecords(); //focus된 cell의 정보를 가져온다
      const deletList = selectRecord.map(data=> data.roomId);
      console.log(deletList);
      // console.log("selectRecord",selectRecord);
      if (
          await this.confirm(
              `객실을 삭제하시겠습니까?`
          )
      ) {
        await GolfErpAPI.deleteRoomDetailNumberSeveral(deletList);
        await this.fetch();
        await this.infoToast("삭제되었습니다");
      }
    },
    onClickExcel() {
      this.$refs.grid.excelExport();
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
          ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
          : {mode: 'Both' , type: 'Multiple'};
      this.$refs.grid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async gridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.gridOptions.columns
          ?.forEach(column => {
            if (column?.columns) {
              column.columns
                  ?.forEach(c => columns.push(c));
            } else {
              columns.push(column);
            }
          });
      const allowedEditColumns = columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
      );
    },
    headerCellInfo(args) {
      const {
        cell: {
          column: {
            field,
          },
        },
        node,
      } = args;
      if (field === "roomNo") {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.gridOptions.columns
            ?.filter(item => item.allowEditing)
            ?.map(item => item.field);

        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    onGridActionComplete() {
      this.count = this.$refs.grid?.getGridBatchCount() || 0  ;
    },
    queryCellInfo(args) {
      const {
        column: {
          field,
          allowEditing
        },
        data,
        cell,
      } = args;
      if (allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "roomNo") {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      } else if(field === 'roomStatus'){
        cell.style.background = commonCodesGetColorValue("ROOM_STATUS",data.roomStatus);
      } else if(field === 'cleanStatus'){
        cell.style.background = commonCodesGetColorValue("CLEAN_STATUS",data.cleanStatus);
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.gridOptions.columns
            ?.filter(item => item.allowEditing)
            ?.map(item => item.field);

        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    addRoomNumber() {
      this.isroomNumberRegistrationPopup = true;
      this.$nextTick(() => {
        this.$refs.roomNumberRegistrationPopup.showPopup();
      });
    },
    popupClosed() {
      this.isroomNumberRegistrationPopup = false;
    },
    excel() {
      this.$refs.grid.excelExport();
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.grid.updateCell(
            index,
            field,
            value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
  },
};
</script>
